import React, { useState, useEffect, useContext } from 'react'
import { IconButton, TextField, Divider, debounce } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { GridColDef, GridValueGetterParams, ptBR } from '@mui/x-data-grid'
import { Header, Content, Image, BotaoDelete, StyledDataGrid } from './styles'
import axios from '../../../services/withAxios';
import VendasIcon from '../../../assets/vendas-icon.png'
import Venda from '../../../interfaces/venda'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { GFLContext } from '../../../infra/GFLContext'
import DownloadVendaProps from '../../../interfaces/downloadVendaProps'
import VendaListagem from '../../../interfaces/vendaListagem'
import { DownloadVenda } from '../../../services/DownloadVenda'
import DeleteIcon from '@mui/icons-material/Delete'
import { useConfirm } from 'material-ui-confirm'
import { EsconderPorNivelAcesso } from '../../../services/auth'
import { NivelAcessoEnum } from '../../../utils/enum/nivelAcessoEnum'
import { TypeAlertEnum } from '../../../utils/enum/typeAlertEnum'
import { datadogLogs } from '@datadog/browser-logs'

const Vendas: React.FC = () => {
  const [pesquisa, setPesquisa] = useState<string>('')
  const [dialogAberto, setDialogAberto] = useState<boolean>(false)
  const [vendas, setVendas] = useState<VendaListagem[]>([])
  const [pesquisarTrigger, setPesquisarTrigger] = useState<boolean>(false);

  const contexto = useContext(GFLContext)
  const confirm = useConfirm()

  const pesquisar = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setPesquisa(event.target.value);
  }, 500)

  useEffect(() => {
    if (dialogAberto)
      return

    const buscarDados = async () => {
      if (pesquisa === '')
        await axios('/venda')
          .then(resp => {
            setVendas(resp.data === '' ? [] : resp.data)
          })
      else
        await axios(`/venda/search/${pesquisa}`)
          .then(resp => {
            setVendas(resp.data === '' ? [] : resp.data)
          })
    }

    buscarDados()
  }, [pesquisa, pesquisarTrigger, dialogAberto])

  const handleDownloadVenda = (item: VendaListagem) => {
    axios.get(`/venda/${item.id}`)
      .then((response) => {
        const venda = response.data as Venda
        const downloadProps: DownloadVendaProps = {
          cliente: {
            cpf: venda.clienteCPF,
            nome: venda.clienteNome,
            observacao: venda.clienteObservacao,
            telefone: venda.clienteTelefone,
          },
          formaPagamento: venda.formaPagamento,
          desconto: venda.valorDesconto,
          valorTotal: item.valorTotal,
          dataVenda: item.data,
          vendaItens: venda.itens,
          contexto: contexto
        }

        try {
          DownloadVenda(downloadProps)
        } catch (e: any) {
          // datadogLogs.logger.error(`Erro ao imprimir venda`, e);
          contexto.setMessageAlert(`Houve um problema ao imprimir a venda. Log: ${e}`)
          contexto.setTypeAlert(TypeAlertEnum.error)
          contexto.setOpenAlert(true)
        }
      })
  }

  const handleDeleteVenda = (item: VendaListagem) => {
    confirm({ description: 'Excluir permanentemente?', title: 'Tem certeza?', confirmationButtonProps: { autoFocus: true } })
      .then(() => {
        axios.delete(`/venda/${item.id}`).then(() => { setPesquisarTrigger(!pesquisarTrigger) })
      });
  }

  const colunas: GridColDef[] = [
    {
      field: 'clienteNome',
      headerName: 'Cliente',
      width: 300,
      valueGetter: (getter: GridValueGetterParams) => {
        return getter.value === null ? '-' : getter.value
      }
    },
    {
      field: 'data',
      headerName: 'Data',
      flex: 1,
      valueGetter: (data: GridValueGetterParams<Date, any>) => {
        return new Date(data.value).toLocaleString().slice(0, -3);
      }
    },
    {
      field: 'valorDesconto',
      headerName: 'Desconto',
      flex: 1,
      valueFormatter: (params: any) => {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(params.value)
      }
    },
    {
      field: 'valorTotal',
      headerName: 'Total',
      flex: 1,
      valueFormatter: (params: any) => {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(params.value)
      },
    },
    {
      field: 'download',
      headerName: '',
      width: 100,
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <>
            <IconButton color="success" onClick={() => handleDownloadVenda(cellValues.row as VendaListagem)} component="span">
              <FileDownloadIcon />
            </IconButton>
            <Divider orientation="vertical" variant="middle" flexItem />
            <EsconderPorNivelAcesso nivelAcesso={[NivelAcessoEnum.admin, NivelAcessoEnum.gerente]}>
              <>
                <BotaoDelete color="error" onClick={() => handleDeleteVenda(cellValues.row as VendaListagem)} component="span">
                  <DeleteIcon />
                </BotaoDelete>
              </>
            </EsconderPorNivelAcesso>
          </>
        );
      }
    }
  ]

  return (
    <>
      <Image src={VendasIcon} />
      <Header>
        <TextField
          id="pesquisa"
          label="Pesquisar"
          onChange={pesquisar}
          type="search"
          fullWidth
          autoFocus
          autoComplete='off'
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
        <IconButton
          color="primary"
          onClick={_ => setPesquisarTrigger(!pesquisarTrigger)}
        >
          <SearchIcon />
        </IconButton>
      </Header>
      <Content>
        <StyledDataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          columns={colunas}
          rows={vendas}
          disableColumnMenu={true}
          hideFooterSelectedRowCount={true}
          sx={{
            textTransform: 'uppercase'
          }}
        />
      </Content>
    </>
  )
}

export default Vendas