import { useContext, useMemo, useEffect, useState } from 'react'
import { GFLContext } from '../infra/GFLContext';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { getToken, logout } from '../services/auth'
import { TypeAlertEnum } from '../utils/enum/typeAlertEnum'
import { useNavigate } from "react-router-dom"
import { datadogLogs } from '@datadog/browser-logs'

var axiosInstance = axios.create({
    //baseURL:  'https://localhost:5001/api'
    //baseURL:  'http://schererbrothers-001-site2.htempurl.com/api'
    //baseURL:  'https://prod-lima-appservicegflapi.azurewebsites.net/api'
    baseURL:  'https://prd-lima-appservicegflapi.azurewebsites.net/api'
})

var interceptorsAlreadySet = false;

const WithAxios = ({ children }: { children: JSX.Element }) => {
    const contexto = useContext(GFLContext)
    const [isSet, setIsSet] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        
        const methodsToUseLoading = (method: string) => {
            return method === 'GET' || method === 'POST' || method === 'PUT' || method === 'DELETE'
        }
    
        const methodsToUseAlert = (method: string) => {
            return method === 'POST' || method === 'PUT' || method === 'DELETE'
        }
    
        const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
            //  if (config)
            //      datadogLogs.logger.info(`REQUEST - ${config.method?.toUpperCase()} ${config.url?.toUpperCase()} `, config)
    
            const token = getToken();
            if (token && config && config.headers) {
                config.headers.Authorization = `Bearer ${token}`
            }
    
            if (methodsToUseLoading(config.method?.toUpperCase() || "") && !config.params?.avoidLoadingScreen)
                contexto.setLoading!(true)
    
            return config;
        }
    
        const onRequestError = (error: AxiosError): Promise<AxiosError> => {
            // if (error)
            //     datadogLogs.logger.error(`REQUEST ERROR`, error)
    
            return Promise.reject(error);
        }
    
        const onResponse = (response: AxiosResponse): AxiosResponse => {
            contexto.setLoading!(false);
    
            if (!response || !response.config)
                return response;
    
            if (methodsToUseAlert(response.config.method?.toUpperCase() || '') && !response.config.params?.avoidFeedbackMessage) {
                contexto.setMessageAlert("Sucesso!")
                contexto.setTypeAlert(TypeAlertEnum.success)
                contexto.setOpenAlert(true)
            }
    
            return response;
        }
    
        const onResponseError = (error: AxiosError): Promise<AxiosError> => {
            contexto.setLoading!(false);
    
            if (!error || !error.config || !error.config.method || !error.response)
                return Promise.reject(error);
    
            if (methodsToUseLoading(error.config.method.toUpperCase() || '')) {
                contexto.setMessageAlert(error.response.data.substring(0, 250))
                contexto.setTypeAlert(TypeAlertEnum.error)
                contexto.setOpenAlert(true)
            }
    
            if (error.response.status === 401) {
                logout()
                navigate("/login")
                contexto.setMessageAlert("Login expirado, por favor entre com suas credenciais novamente.")
                contexto.setTypeAlert(TypeAlertEnum.info)
                contexto.setOpenAlert(true)
            }
    
            return Promise.reject(error);
        }

        if (!interceptorsAlreadySet) {
            axiosInstance.interceptors.request.use(onRequest, onRequestError)
            axiosInstance.interceptors.response.use(onResponse, onResponseError)
        }

        setIsSet(true)
        interceptorsAlreadySet = true;

    }, [navigate, contexto])

    return isSet ? children : null
}

export default axiosInstance;
export { WithAxios }